@media screen and (max-width: 800px) {
  #alumni-bios-button-navbar,
  #class-pages-button-navbar,
  #home-button-navbar,
  #login-logout-button-navbar,
  #photos-button-navbar,
  #yearbook-button-navbar {
    display: none;
  }
}
