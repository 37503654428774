.Landing-pic-one{
    width: 100%;
    margin: 0rem 0 0.25rem 0;
}

.tpsGradPatch {
    width: 80%;
}

.Landing-pic-two {
    width: 55%;
    margin-top: 2rem;
}

.Landing-pic-three {
    width: 30%;
    margin-top: 2rem;
}

.notableAlumni {
    height: 800px;
    overflow: scroll
}

.notableAlumniHeader {
    position: sticky;
    top: 20px;
}

.Landing-announcements {
    height: 416px;
    overflow: scroll
}

.Landing-announcements-Header {
    position: sticky;
    top: 20px;
}

/* Active when screen with is less than 1400px */
@media screen and (max-width: 1400px) {
    .bio-div {
        margin: 0.5rem;
    }
 }

 /* Active when screen with is less than 1300px */
@media screen and (max-width: 1300px) {
    .bio-div {
        margin: 1rem;
    }
 }

 /* Active when screen with is less than 1100px */
@media screen and (max-width: 1100px) {
    .tpsGradPatch {
        width: 50%;
    }
 }

 /* Active when screen with is less than 960px which is the sm breakpoint in material-ui */
@media screen and (max-width: 960px) {
    .landing-notable-alumni-main-div {
        padding-top: 1.5rem;
    }
    .tpsGradPatch {
        width: 40%;
    }
 }