.search-by-name-box {
   border: 1px solid blue;
   border-radius: 0.5rem;
   height: 150px;
   margin-top: 2rem;
   margin-left: 4rem;
}

 /* Active when screen with is less than 960px which is the sm breakpoint in material-ui */
 @media screen and (max-width: 960px) {
   .search-by-name-box {
      height: 200px;
      width: 400px;
   }
}